import * as React from "react";
import PageComponent from "src/components/pages/determine-your-health-class";

const Page = ({ location }) => {
  const copy = {
    backLink: "Return to get a quote",
    footNote:
      "* Individual Consideration on a case-by-case basis – may or may not be eligible.",
    headerRow: [
      "Preferred Plus Non-Tobacco",
      "Preferred Non-Tobacco",
      "Regular Plus (Standard Plus) Non-Tobacco",
      "Regular (Standard) Non-Tobacco",
      "Preferred Tobacco",
      "Standard Tobacco",
    ],
    rows: [
      {
        header: "Tobacco Usage",
        cells: [
          "None in past 5 years",
          {
            _: "None in past 2 years",
            colspan: 3,
          },
          { _: "Tobacco Permitted", colspan: 2 },
        ],
      },
      {
        header: "Cholesterol / HDL Ratio",
        cells: ["5.0", "5.5", "6.2", "7.0", "5.5", "7.0"],
      },
      {
        header: "Blood Pressure",
        cells: [
          "130/80 for ages < 49; 135/85 for ages >50",
          "130/85 for ages < 49; 140/90 for ages >50",
          "140/90 for ages < 49; 145/90 for ages >50",
          { _: "*", colspan: 3 },
        ],
      },
      {
        header:
          "Family History: Includes coronary artery disease and the following cancers: Breast, Ovarian, Prostate, Colon, Lung, Melanoma",
        cells: [
          "No family deaths before age 60 of either parent or sibling.",
          { _: "No family deaths before age 60 of either parent.", colspan: 2 },
          "*",
          "No family deaths before age 60 of either parent.",
          "*",
        ],
      },
      {
        header: "Personal Medical History",
        cells: [
          {
            _: "No heart or vascular disease, diabetes, or cancer (except some skin cancers)",
            colspan: 3,
          },
          "No ratable impairments",
          "No heart or vascular disease, diabetes, or cancer (except some skin cancers)",
          "No ratable impairments",
        ],
      },
      {
        header: "Driving History",
        cells: [{ _: " ", colspan: 6 }],
      },
      {
        header: "DUI/Reckless",
        cells: [
          { _: "None in past 5 years", colspan: 3 },
          "*",
          "None in past 5 years",
          "*",
        ],
      },
      {
        header: "Major Violations",
        cells: [
          "No more than one in past 3 years; NONE in past 12 months",
          {
            _: "None in past 5 years",
            colspan: 2,
          },
          "*",
          "None in past 5 years",
          "*",
        ],
      },
      {
        header: "Minor Violations",
        cells: [
          {
            _: "No more than 3 minor moving violations in past 3 years",
            colspan: 3,
          },
          "*",
          "No more than 3 minor moving violations in past 3 years",
          "*",
        ],
      },
      {
        header: "Alcohol/Substance Abuse",
        cells: [
          {
            _: "No history or treatment at any time",
            colspan: 2,
          },
          "*",
          "No history or treatment in past 7 years",
          "No history or treatment at any time",
          "No history or treatment in past 7 years",
        ],
      },
      {
        header: "Aviation",
        cells: [
          "Only available with Aviation Exclusion Rider; not available to 71 and older",
          "No ratable aviation activities",
          "No occupation related ratable aviation activities",
          "Insurability and ratings depend on actual aviation history",
          "No ratable aviation activities",
          "Insurability and ratings depend on actual aviation history",
        ],
      },
      {
        header: "Avocation (Hazardous)",
        cells: [
          {
            _: "No participation in the following activities: aeronautics (e.g. hang-gliding, ultralight, soaring, skydiving, ballooning, etc.) power racing, competitive vehicles, mountain climbing, rodeos, competitive skiing or scuba/skin diving at a depth greater than 75 feet.",
            colspan: 6,
          },
        ],
      },
      {
        header: "Military",
        cells: [
          {
            _: "Active military duty is acceptable provided the proposed insured is not serving in a hazardous area or does not have orders to serve in a hazardous area. Unless otherwise prohibited by statute.",
            colspan: 6,
          },
        ],
      },
    ],
  };
  return (
    <PageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="Determine your health class"
    />
  );
};

export default Page;
